import Link from 'next/link';
import { VideoClass } from '@/lib/types/api/video';
import { formatVideoMetadata } from '@/lib/helpers/format-video-metadata';
import { fromSeconds } from '@/lib/helpers/from-seconds';
import MyListButton from '@/components/Button/MyList/MyListButton';
import AccessibilityIcons from '@/components/AccessibilityIcons/AccessibilityIcons';
import VideoMezzanineLink from '@/components/VideoMezzanineLink/VideoMezzanineLink';
import styles from './VideoDetailThumbnail.module.scss';
type VideoDetailThumbnailProps = {
  video: VideoClass;
  gtmLabel?: string;
  nowPlaying?: boolean;
  displayShowName?: boolean;
  // this prop is really here for storybook development
  depIsNew?: boolean;
  depPercentageWatched?: number;
};
const VideoDetailThumbnail = (props: VideoDetailThumbnailProps) => {
  const {
    video,
    gtmLabel,
    nowPlaying = false,
    displayShowName = false,
    depIsNew = false,
    depPercentageWatched = 0
  } = props;
  const {
    title,
    slug,
    description_short: description,
    show
  } = video;
  const epMeta = formatVideoMetadata(video, "shortEpOnly");
  const formattedDuration = fromSeconds(video.duration);
  const shouldDisplayEpisodeNumber = video.parent?.season?.show?.display_episode_number;
  let text = description;
  if (epMeta && epMeta !== '' && shouldDisplayEpisodeNumber) {
    text = `${epMeta} | ` + text;
  }
  if (formattedDuration && formattedDuration !== '') {
    text = text + ` (${formattedDuration})`;
  }
  return <div className={styles.video_detail_thumbnail_container} data-sentry-component="VideoDetailThumbnail" data-sentry-source-file="VideoDetailThumbnail.tsx">
      <div className={styles.video_detail_thumbnail}>
        <VideoMezzanineLink video={video} gtmLabel={gtmLabel} nowPlaying={nowPlaying} depIsNew={depIsNew} depPercentageWatched={depPercentageWatched} data-sentry-element="VideoMezzanineLink" data-sentry-source-file="VideoDetailThumbnail.tsx" />
        <p className={styles.video_title}>
          {displayShowName && video.show && video.show.title && <span className={styles.video_detail_show_title}>
              <Link href={`/show/${video.show.slug}/`}>
                {video.show.title}
              </Link>
            </span>}
          <Link href={`/video/${slug}/`} data-sentry-element="Link" data-sentry-source-file="VideoDetailThumbnail.tsx">{title}</Link>
        </p>
        <p className={styles.video_detail_accessibility_icons}>
          <AccessibilityIcons video={video} preceedWithPipe={false} data-sentry-element="AccessibilityIcons" data-sentry-source-file="VideoDetailThumbnail.tsx" />
        </p>
        <p className={styles.video_description}>
          {text}
        </p>

        <MyListButton video={video} style='iconOnly' data-sentry-element="MyListButton" data-sentry-source-file="VideoDetailThumbnail.tsx" />

      </div>
    </div>;
};
export default VideoDetailThumbnail;