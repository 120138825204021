'use client';

import { useState, useEffect } from 'react';
import LinkIcon from '../../public/svg/link.svg';
import CheckIcon from '../../public/svg/check.svg';
import styles from './CopyButton.module.scss';
interface CopyButtonProps {
  className?: string;
  text: string;
}
const CopyButton: React.FC<CopyButtonProps> = props => {
  const {
    className,
    text
  } = props;
  const [isCopied, setIsCopied] = useState(false);
  const [showCallout, setShowCallout] = useState(false);
  let classNames = `${styles.copy_button}`;
  if (className) {
    classNames += ` ${className}`;
  }
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
    } catch (error) {
      console.log("Error", error);
    }
  };
  let copiedTextStyles = styles.copy_button__copied_text;
  if (showCallout) {
    copiedTextStyles += ` ${styles.show}`;
  }
  useEffect(() => {
    if (isCopied) {
      setShowCallout(true);
      const timeoutID = setTimeout(() => {
        setIsCopied(false);
        setShowCallout(false);
      }, 3000);
      return () => {
        clearTimeout(timeoutID);
      };
    }
  }, [isCopied]);
  return <div className={classNames} data-sentry-component="CopyButton" data-sentry-source-file="CopyButton.tsx">
      <button onClick={handleCopyClick} title="Copy link to video" disabled={isCopied}>
        {isCopied ? <CheckIcon /> : <LinkIcon />}
      </button>
      <span className={copiedTextStyles} aria-live="polite" aria-hidden={!showCallout}>
        Link Copied to Clipboard
      </span>
    </div>;
};
export default CopyButton;